import { nanoid } from 'nanoid'

// HEAD DATA
export const headData = {
  title: 'Daniel Cooper | Developer', // e.g: 'Name | Developer'
  lang: 'en',
  description: 'Welcome to my portfolio site.', // e.g: Welcome to my website
}

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Daniel',
  subtitle: "I'm a Junior Software Engineer and Web Developer",
  cta: 'Learn More',
}

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne:
    'I am a legal clerk and Web Development TA looking to take the next step in my professional life by pursuing my passion for coding in the field of Software Development. Learning never stops, and by engaging with new concepts I can continuously grow.',
  paragraphTwo: 'In my free time I enjoy writing science-fantasy stories, game development, and learning new things.',
  paragraphThree: '',
  resume: 'https://drive.google.com/file/d/1J2WJ3KkMZipx9RY4LvfoilzctU33xMlc/view?usp=sharing', // if no resume, the button will not show up
}

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.png',
    title: 'Flora Seeker API',
    info:
      'Using a world designed for a hobby project, this RESTful API utilizes an MVC design pattern with Express, MySQL, and Sequelize.js. It is documented with a pug view, and was my first personal API project over the course of my learning.',
    info2:
      '"We, the Seras Seekers, are a fledgling Flora Seeker Branch. Out here in Seras, we have unparalleled opportunity to thrive. Seras\'s Research Division has committed itself to supporting us while we get established. This API is one example of that. In turn, the Research Advocate and the Seras Council as a whole expect great things. Use the Flora Seeker Database to manage activities efficiently, and stay true to the Division guidelines!"',
    url: 'https://github.com/floranova13/flora-seeker-api',
    repo: 'https://github.com/floranova13/flora-seeker-api', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.png',
    title: 'Employee Cost of Loss Calculator',
    info:
      '"Open Avenues Foundation launches programs that create upward mobility for people and communities in the United States." Companies need talent but are wary of the visa and immigration process. Using an excel spreadsheet provided by OAF, we created an application in React to store lead generation information (user name, contact, etc) and provide a "cost demo" that calculates and shows the cost of losing a foreign-born employee.',
    info2:
      'The RESTful API back-end uses MySQL and Sequelize.js. The React front-end shows allows to access the cost demo. And uses the Axios package to post user information when a PDF is printed.',
    url: 'https://github.com/floranova13/cost-of-loss-calculator',
    repo: 'https://github.com/floranova13/cost-of-loss-calculator', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.png',
    title: 'Anamnesis',
    info:
      'This is a slight modification of the well-known card memory game. A grid of cards is revealed to the player. When The game begins, all cards are turned over so only their identical backs are revealed.',
    info2: '',
    url: 'https://github.com/floranova13/react-memory-game',
    repo: 'https://github.com/floranova13/react-memory-game', // if no repo, the button will not show up
  },
]

// CONTACT DATA
export const contactData = {
  cta: 'Open for work!',
  btn: 'daniel.s.cooper.dev@gmail.com',
  email: 'daniel.s.cooper.dev@gmail.com',
}

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'file',
      url: 'https://drive.google.com/file/d/1J2WJ3KkMZipx9RY4LvfoilzctU33xMlc/view?usp=sharing',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/floranova13',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/daniel-s-cooper/',
    },
    {
      id: nanoid(),
      name: 'stack-overflow',
      url: 'https://stackoverflow.com/users/story/14135038',
    },
  ],
}
